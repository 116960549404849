var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"data-table",class:_vm.canBeSelected ? 'data-table--selectable' : '',attrs:{"elevation":"4"}},[_c('v-data-table',{staticClass:"elevation-0 custom-data-table",attrs:{"calculate-widths":"","headers":_vm.headers,"items":_vm.itemsLoading ? [] : _vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"search":_vm.searchTerm,"sort-by":_vm.sortBy.value,"sort-desc":_vm.sortDesc,"hide-default-footer":"","hide-default-header":"","server-items-length":_vm.itemsTotal},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([(_vm.itemsLoading)?{key:"body",fn:function(){return [(_vm.$vuetify.breakpoint.lgAndDown)?_vm._l((5),function(item){return _c('config-table-item-skeleton-mobile',{key:item})}):[_c('config-table-skeleton-sort'),_c('config-table-skeleton-item')]]},proxy:true}:null,{key:"no-data",fn:function(){return [(_vm.searchTerm)?_c('data-iterator-no-results-container',{attrs:{"searchTerm":_vm.searchTerm},on:{"clearAllFilters":function($event){return _vm.clearFilters()}}}):_vm._e()]},proxy:true},(_vm.$vuetify.breakpoint.width >= 1400 && !_vm.itemsLoading)?{key:"header",fn:function(ref){
var isMobile = ref.isMobile;
var props = ref.props;
var on = ref.on;
return [_c('table-custom-sort',_vm._g({attrs:{"isMobile":isMobile,"props":props,"v-on":on}},_vm.$listeners))]}}:null,{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"bold"},[_vm._v(_vm._s(item.name ? item.name : "---"))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.value ? item.type !== "secret" ? item.value : _vm.hideSecret(item.value) : "---"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex actions-row"},[_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('action-button-edit', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.config.edit')))])]),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('action-button-delete', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$thrash")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.config.delete')))])])],1)]}},(_vm.$vuetify.breakpoint.width < 1400)?{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('tr',[_c('td',{staticClass:"w-100 px-0"},[_c('div',{staticClass:"mobile-table-item"},[_c('div',{staticClass:"mobile-table-item__values"},[_c('div',{staticClass:"mobile-table-item__row"},[_c('div',{staticClass:"mobile-table-item__header",on:{"click":function($event){return _vm.$emit('update:sortBy', headers[0])}}},[_vm._v(" "+_vm._s(_vm.$t('table.header.name'))+" ")]),_c('div',{staticClass:"mobile-table-item__value"},[_c('span',{staticClass:"bold p-3"},[_vm._v(_vm._s(item.name))])])]),_c('div',{staticClass:"mobile-table-item__row"},[_c('div',{staticClass:"mobile-table-item__header",on:{"click":function($event){return _vm.$emit('update:sortBy', headers[1])}}},[_vm._v(" "+_vm._s(_vm.$t('table.header.value'))+" ")]),_c('div',{staticClass:"mobile-table-item__value"},[_c('span',{staticClass:"bold p-3"},[_vm._v(_vm._s(item.value ? item.type !== "secret" ? item.value : _vm.hideSecret(item.value) : "---"))])])]),_c('div',{staticClass:"mobile-table-item__row"},[_c('div',{staticClass:"mobile-table-item__header",on:{"click":function($event){return _vm.$emit('update:sortBy', headers[2])}}},[_vm._v(" "+_vm._s(_vm.$t('table.header.type'))+" ")]),_c('div',{staticClass:"mobile-table-item__value"},[_c('span',{staticClass:"bold p-3"},[_vm._v(_vm._s(item.type))])])]),_c('div',{staticClass:"mobile-table-item__actions"},[_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('action-button-edit', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.config.edit')))])]),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('action-button-delete', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$thrash")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.config.delete')))])])],1)])])])])]}}:null,{key:"footer",fn:function(){return [_c('div',[_c('data-iterator-footer',_vm._g({attrs:{"page":_vm.page,"numberOfPages":_vm.numberOfPages,"possibleItemsPerPage":_vm.possibleItemsPerPage,"listStyle":_vm.listStyle,"itemsPerPage":_vm.itemsPerPage,"itemsTotal":_vm.itemsTotal}},_vm.$listeners))],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }